import React, { useState, useEffect } from 'react'
import { Helmet } from 'react-helmet'
import '../../assets/scss/sub-1.css'
import bannerImg from '../../assets/img/Greetings/Banner02.jpg'
import img01 from '../../assets/img/Recruit04/Recruit04_01.png'
import img02 from '../../assets/img/Recruit04/Recruit04_03.png'
import img03 from '../../assets/img/Recruit04/Recruit04_04.png'
import Contact from './Contact'
import square from '../../assets/img/Work01/square.png'

function Contents(e) {
  const [isActive, setActive] = useState(false)

  const style = {
    logoImg: {
      backgroundImage: `url(${bannerImg})`,
    },
    imgSize: {
      backgroundImage: `url(${img01})`,
      height: '10px',
      width: '10px',
    },
  }

  useEffect(() => {
    setTimeout(() => {
      setActive(true)
    }, 50)
  }, [])

  return (
    <div>
      <Helmet>
        <script type="text/javascript" src="/jquery-3.3.1.js"></script>
        <script
          type="text/javascript"
          src="/jquery.mCustomScrollbar.css"
        ></script>
        <script type="text/javascript" src="/jquery-migrate-1.4.1.js"></script>
        <script
          type="text/javascript"
          src="https://cdnjs.cloudflare.com/ajax/libs/jquery/3.1.1/jquery.min.js"
        ></script>
        <script
          type="text/javascript"
          src="https://cdnjs.cloudflare.com/ajax/libs/jquery-cookie/1.4.1/jquery.cookie.js"
        ></script>
        <script type="text/javascript" src="/_sub-side-menu.js"></script>
      </Helmet>
      {/* <section className="subvisu-sec visu-top-gap "> */}
      <section
        className={`subvisu-sec visu-top-gap ${isActive ? 'start' : ''}`}
      >
        <div className="visu-bg" style={style.logoImg}></div>
        <div className="left-bg"></div>
        <div className="right-bg"></div>
        <p>SI Professional Partner</p>
        <h4 style={{ color: 'rgb(16, 181, 236)'  }}>-</h4>
        <h3 style={style.margin}>
          {' '}
          信頼と肯定を誇り持って、価格創出を追求する企業
        </h3>
      </section>
      <section className="cont-sec">
        <div className="con-cont">
          <div className="content-box">
            <div className="left-menu-box">
              <dl id="stick" className="left-menu-dl natural">
                <dt>　採用情報</dt>
                <dd>
                  <a href="/recruit01">インフラ</a>
                </dd>
                <dd>
                  <a href="/recruit02">無線通信</a>
                </dd>
                <dd>
                  <a href="/recruit03">開発</a>
                </dd>
                <dd>
                  <a href="/contact">応募登録</a>
                </dd>
              </dl>{' '}
            </div>
            <div id="contbox" className="right-cont-box">
              <div className="contex-box">
                <h2>
                  {' '}
                  <span>
                    {' '}
                    <img src={square} className="Square"></img>
                  </span>{' '}
                  簡単に！応募登録
                </h2>
                <div className="contactSize">
                  <Contact />
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="top"></div>
    </div>
  )
}

export default Contents
