import React from 'react'
import bannerImg from '../../assets/img/Greetings/Banner02.jpg'
import img01 from '../../assets/img/Recruit04/Recruit04_01.png'
import img03 from '../../assets/img/Recruit04/kakunin.png'
import footimg02 from '../../assets/img/Recruit04/Recruit04_03-1.png'
import footimg03 from '../../assets/img/Recruit04/Recruit04_04.png'
import emailjs from 'emailjs-com';
import btn from '../../assets/img/Access/btn3.png'
import btn2 from '../../assets/img/Access/btn4.png'

export const ContactForm = () => {

  const handleMouseEnter = e => {
    e.target.src = btn2
  }
  const handleMouseLeave = e => {
    e.target.src = btn
  }

  const style = {
    logoImg: {
      backgroundImage: `url(${bannerImg})`,
    },
    imgSize: {
      backgroundImage: `url(${img01})`,
    },
    span: {
      fontColor: '#d3d3d3',
      align: 'center',
    },

    tdEmail: {
      borderCollapse: 'collapse',
      fontSize: '1em',
      paddingLeft: '5.5%',
      width: '20%',
      fontFamily: 'Noto Sans JP',
    },
    tdkakunin: {
      backgroundImage: 'url(' + img03 + ')',
    },
    btn: {
      width: '70%',
      height: '70%',
      backgroundSize: 'contain',
    },
    btn2: {
      width: '70%',
      height: '70%',
      backgroundSize: 'contain',

    },
    btnBg: {
      backgroundColor: '#ffffff',
    },
  }
  // const onChange = event => {
  //   // const value = event.target.value;

  //   // // this will return C:\fakepath\somefile.ext
  //   // console.log(value);

  //   const files = event.target.files

  //   //this will return an ARRAY of File object
  //   console.log(files)
  //   console.log(files)
  // }

  function sendEmail(e) {
    e.preventDefault();
    const answer = window.confirm('メールを送信しますか？')
    if (answer) {
    emailjs.sendForm('service_9j075z4', 'template_GHI', e.target, 'user_yqajUaJytOV1aEd7S5xHT')
      .then((result) => {
          console.log(result.text);
          alert('応募フォームを送信しました。\nご応募ありがとうございました。');

      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  }
}

  return (
    <div>
      <div className="content-box">
      <form onSubmit={sendEmail}>

          <br></br>
          <div className="contactWeb">
            <div className="item-wrapper">
              <img src={img01} className="recruit04Img01" />
            </div>
            <div className="item-info-box">
              <div id="table" className="contactWeb">
                <table className="tableSize2">
                  <tr className="contacttr">
                    <td className="contactTitle" align="center" colSpan="8">
                      ○　フォームからの応募　○
                    </td>
                  </tr>
                  <tr className="contacttr">

                    <td className="contactTd01">応募別</td>
                    <td className="contactTd02" align="left">
                      <span
                        style={{ backgroundColor: '#c7b299', color: 'white',fontSize:'80%' }}
                      >
                        　必須　
                      </span>
                    </td>
                    <td className="contactTd03" align="left" colSpan="6">
                    <input type='hidden' name="title" value="応募メールが届きました。"></input>
                      <input
                        type="radio"
                        name="select"
                        value="開発"
                        required
                      />
                      　開発
                      <span>　</span>
                      <input
                        type="radio"
                        name="select"
                        value="ネットワーク"
                        required
                      />
                      　ネットワーク
                      <span>　</span>
                      <input
                        type="radio"
                        name="select"
                        value="モバイル開発"
                        required
                      />
                      　モバイル開発
                      <br></br>
                      <input
                        type="radio"
                        name="select"
                        value="システム"
                        required
                      />
                      　システム
                      <span>　</span>
                      <input
                        type="radio"
                        name="select"
                        value="データベース"
                        required
                      />
                      　データベース
                      <span>　</span>
                      <input
                        type="radio"
                        name="select"

                        value="その他"
                        required
                      />
                      　その他
                      <span>　</span>
                    </td>
                  </tr>
                  <tr className="contacttr">
                    <td className="contactTd01" align="left">
                      氏名
                    </td>
                    <td className="contactTd02" align="left">
                      <span
                        style={{ backgroundColor: '#c7b299', color: 'white' ,fontSize:'80%'}}
                      >
                        　必須　
                      </span>
                    </td>
                    <td className="contactTd04" align="left" colSpan="1">
                      姓
                    </td>
                    <td className="contactTd05" align="left" colSpan="2">
                      <input
                        type="text"
                        name="firstName"

                        className="contactTb-S"
                        required
                      ></input>
                    </td>
                    <td className="contactTd04" align="left" colSpan="1">
                      　名
                    </td>
                    <td className="contactTd05" align="left" colSpan="2">
                      <input
                        type="text"
                        name="lastName"
                        text="&nbsp;"
                        className="contactTb-S"
                        required
                      ></input>
                    </td>
                  </tr>
                  <tr className="contacttr">
                    <td className="contactTd01" align="left">
                      氏名・フリガナ
                    </td>
                    <td className="contactTd02" align="left">
                      <span
                        style={{ backgroundColor: '#c7b299', color: 'white',fontSize:'80%' }}
                      >
                        　必須　
                      </span>
                    </td>
                    <td className="contactTd04" align="left" colSpan="1">
                      姓
                    </td>
                    <td className="contactTd05" align="left" colSpan="2">
                      <input
                        type="text"
                        name="firstHuri"
                        className="contactTb-S"
                        required
                      ></input>
                    </td>
                    <td className="contactTd04" align="left" colSpan="1">
                      　名
                    </td>
                    <td className="contactTd05" align="left" colSpan="2">
                      <input
                        type="text"
                        name="lastHuri"
                        className="contactTb-S"
                        required
                      ></input>
                    </td>
                  </tr>
                  <tr className="contacttr">
                    <td className="contactTd01" align="left">
                      メールアドレス
                    </td>
                    <td className="contactTd02" align="left">
                      <span
                        style={{ backgroundColor: '#c7b299', color: 'white',fontSize:'80%' }}
                      >
                        　必須　
                      </span>
                    </td>
                    <td className="contactmail" align="left" colSpan="6">
                      <input
                        type="text"
                        name="email"
                        className="contactTextbox"
                        required
                      ></input>
                    </td>
                  </tr>
                  <tr className="contacttr">
                    <td className="contactTd01" align="left">
                      電話番号
                    </td>
                    <td className="contactTd02" align="left"></td>
                    <td className="contactmail" align="left" colSpan="6">
                      <input
                        type="text"
                        name="tel"
                        className="contactTextbox"
                      ></input>
                    </td>
                  </tr>
                  <tr className="contacttr">
                    <td className="contactTd01" align="left">
                      問い合わせ内容
                    </td>
                    <td className="contactTd02" align="left">
                      　　
                    </td>
                    <td className="contactmail" align="left" colSpan="6">
                      <textarea
                        className="contactTb-L"
                        name="message"
                        style={{ resize: 'none' }}
                      ></textarea>
                    </td>
                  </tr>
                  {/* <tr className="contacttr">
                    <td className="contactCheck" align="left" colSpan="8">
                      <span className="checkText">
                        最先端技術を追求しているGHIのITプラットフォーム事業、SI事業、統合セキュリティソリューション事業、IoT事業を中心に成長している上場企業です。GHIは志願者の資質と力量をより正確に把握するため、様々な手続きを経て人材を選抜します。
                      </span>
                      <br></br>
                      <span className="checkbox">
                        <input type="checkbox" name="check" value="yes" required/>{' '}
                        同意する　
                        <span
                          style={{ backgroundColor: '#ff0000', color: 'white',fontSize:'80%' }}
                        >
                          　必須　
                        </span>
                      </span>
                    </td>
                  </tr> */}
                  <tr align="center">
                    <td align="center" colSpan="8">
                      <button type="submit" style={style.btnBg} >
                        <img src={btn} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} style={style.btn} alt="" />
                      </button>
                    </td>
                  </tr>
                </table>
                <div style={{ margin: '5%' }}>
                  <img src={footimg02} className="recruit04footImg"></img>
                </div>
                <div style={{ marginTop: '5%' }}>
                  <img src={footimg03} className="recruit04footImg"></img>
                </div>
              </div>
            </div>
          </div>
        </form>
        <form onSubmit={sendEmail}>
          <div className="contactMo">
            <div className="item-wrapper">
              <img src={img01} className="recruit04Img01" />
            </div>
            <div className="item-info-box">
              {/* 모바일 화면 */}
              <div id="table" className="contactMo">
                <span className="contactmo-title">
                  {' '}
                  ○　フォームからの応募　○
                </span>
                <br></br>
                <br></br>

                <span className="contactmo-td01">
                  応募別　
                  <span style={{ backgroundColor: '#c7b299', color: 'white',fontSize:'80%' }}>
                    　必須　
                  </span>
                </span>
                <div className="contactmo-Td03">
                <input type='hidden' name="title" value="応募メールが届きました。"></input>
                  <input type="radio" name="select" value="開発" required />
                  　開発
                  <span>　</span>
                  <input
                    type="radio"
                    name="select"
                    value="ネットワーク"
                    required
                  />
                  　ネットワーク
                  <span>　</span>
                  <input
                    type="radio"
                    name="select"
                    value="モバイル開発"
                    required
                  />
                  　モバイル開発
                  <br></br>
                  <input type="radio" name="select" value="システム" required />
                  　システム
                  <span>　</span>
                  <input
                    type="radio"
                    name="select"
                    value="データベース"
                    required
                  />
                  　データベース
                  <span>　</span>
                  <input type="radio" name="select" value="その他" required />
                  　その他
                  <span>　</span>
                </div>

                <span className="contactmo-td01">
                  氏名　
                  <span style={{ backgroundColor: '#c7b299', color: 'white',fontSize:'80%' }}>
                    　必須　
                  </span>
                </span>
                <div className="contactmo-Td03">
                  姓　
                  <input
                    type="text"
                    name="firstName"
                    className="contactTb-S"
                    required
                  ></input>
                  名
                  <input
                    type="text"
                    name="lastName"
                    className="contactTb-S"
                    required
                  ></input>
                </div>
                <span className="contactmo-td01">
                  氏名・フリガナ　
                  <span style={{ backgroundColor: '#c7b299', color: 'white',fontSize:'80%' }}>
                    　必須　
                  </span>
                </span>
                <div className="contactmo-Td03">
                  姓　
                  <input
                    type="text"
                    name="firstHuri"
                    className="contactTb-S"
                    required
                  ></input>
                  名
                  <input
                    type="text"
                    name="lastHuri"
                    className="contactTb-S"
                    required
                  ></input>
                </div>
                <span className="contactmo-td01">
                  メールアドレス　
                  <span style={{ backgroundColor: '#c7b299', color: 'white',fontSize:'80%' }}>
                    　必須　
                  </span>
                </span>
                <div className="contactmo-Td03">
                  <input
                    type="text"
                    name="email"
                    className="contactTb-S"
                    required
                  ></input>
                </div>
                <span className="contactmo-td01">
                  電話番号　
                  <span style={{ backgroundColor: '#c7b299', color: 'white',fontSize:'80%' }}>
                    　必須　
                  </span>
                </span>
                <div className="contactmo-Td03">
                  <input
                    type="text"
                    name="tel"
                    className="contactTb-S"
                    required
                  ></input>
                </div>
                <span className="contactmo-td01">
                  問い合わせ内容　
                  <span style={{ backgroundColor: '#c7b299', color: 'white',fontSize:'80%' }}>
                    　必須　
                  </span>
                </span>
                <div className="contactmo-Td03">
                  <textarea className="contactTb-L" name="message"  style={{ resize: 'none' }}></textarea>
                </div>
                <div
                  style={{
                    paddingLeft: '15px',
                    paddingRight: '15px',
                  }}
                >
                  {/* <div className="contactCheck" align="left" colSpan="8">
                    <span className="checkText">
                      最先端技術を追求しているGHIのITプラットフォーム事業、SI事業、統合セキュリティソリューション事業、IoT事業を中心に成長している上場企業です。GHIは志願者の資質と力量をより正確に把握するため、様々な手続きを経て人材を選抜します。
                    </span>
                    <br></br>

                    <span className="checkbox">
                      <input type="checkbox" name="check" value="yes" required/>{' '}
                      同意する　
                      <span
                        style={{ backgroundColor: '#ff0000', color: 'white',fontSize:'80%' }}
                      >
                        　必須　
                      </span>
                    </span>
                  </div> */}
                  <div>
                    <button type="submit" style={style.btnBg}>
                      <img
                        src={btn}
                        onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}
                        style={{ width: '40%', height: '40%' }}
                        alt=""
                      />
                    </button>
                  </div>
                </div>

                <div>
                  <div style={{ marginTop: '10%' }}>
                    <img src={footimg02} className="recruit04footImg"></img>
                  </div>
                  <div style={{ marginTop: '10%' }}>
                    <img src={footimg03} className="recruit04footImg"></img>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

export default ContactForm
