import React from 'react'
import MainNav from '../../components/Main/MainNav/MainNav'
import MainNav_m from '../../components/Main/Header/MainNav_m'
// import Contact from '../../components/Contact/Contact'
import Footer from '../../components/Main/Footer/Footer'
import withStyles from '@material-ui/core/styles/withStyles'
import componentsStyle from '../../assets/jss/material-kit-react/views/components'
import ContactUs from '../../components/Contact/ContactUs'


function About(props) {
  const { classes, ...rest } = props
  return (
    <div style={{ backgroundColor: 'white' }}>
      <MainNav />
      <MainNav_m />
      <ContactUs />
      <Footer />
    </div>
  )
}

export default withStyles(componentsStyle)(About)
